import React, { FC } from 'react';
import { Forms } from '@grafana/ui';
import { RadioButtonSize } from '@grafana/ui/src/components/Forms/RadioButtonGroup/RadioButton';
import { ReportOptions, reportLayouts } from '../types';

interface Props {
  options: ReportOptions;
  onChange: (options: ReportOptions) => void;
  size?: RadioButtonSize;
}

export const ReportOptionsPicker: FC<Props> = ({ onChange, options = { landscape: false }, size = 'md' }) => {
  const onLayoutChange = (layout: string) => {
    const newOptions: ReportOptions = {
      ...options,
      landscape: layout === 'landscape',
    };
    onChange(newOptions);
  };

  const selectedLayout = reportLayouts.find(l =>
    options.landscape ? l.value === 'landscape' : l.value === 'portrait'
  );

  return (
    <Forms.Field label="Layout">
      <Forms.RadioButtonGroup
        onChange={onLayoutChange}
        options={reportLayouts}
        value={selectedLayout?.value}
        size={size}
      />
    </Forms.Field>
  );
};
