import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { DateTime, NavModel, SelectableValue } from '@grafana/data';
import { Forms } from '@grafana/ui';

import { DashboardPicker } from 'app/core/components/Select/DashboardPicker';
import Page from 'app/core/components/Page/Page';
import { getRouteParamsId } from 'app/core/selectors/location';
import { appendQueryToUrl, toUrlParams } from 'app/core/utils/url';
import { getNavModel } from 'app/core/selectors/navModel';
import { ReportScheduling } from './ReportScheduling';
import { ReportOptionsPicker } from './ReportOptionsPicker';
import { createReport, loadReport, updateReport } from './state/actions';
import { clearReportState, updateReportProp } from './state/reducers';
import { EnterpriseStoreState, Report, SchedulingFrequency, ReportOptions, ReportDTO } from '../types';
import { validateMultipleEmails } from '../utils/validators';

export interface Props {
  report: Report;
  navModel: NavModel;
  reportId?: number;
  isLoading: boolean;

  loadReport: typeof loadReport;
  clearReportState: typeof clearReportState;
  updateReport: typeof updateReport;
  createReport: typeof createReport;
  updateReportProp: typeof updateReportProp;
}

export class ReportPage extends PureComponent<Props> {
  componentDidMount() {
    const { loadReport, reportId } = this.props;
    if (reportId) {
      loadReport(reportId);
    }
  }

  componentWillUnmount() {
    this.props.clearReportState();
  }

  onModeChange = (frequency: SchedulingFrequency) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        frequency,
      },
    });
  };

  onDayOfWeekChange = (dayOfWeek: SelectableValue<string>) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        day: dayOfWeek.value,
      },
    });
  };

  onTimeOfDayChange = (timeOfDay: DateTime) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        hour: timeOfDay.hour(),
        minute: timeOfDay.minute(),
      },
    });
  };

  onTimeZoneChange = (timeZone: string) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        timeZone,
      },
    });
  };

  onDashboardChange = (dashboard: SelectableValue<number>) => {
    if (dashboard) {
      const { report, updateReportProp } = this.props;
      updateReportProp({
        ...report,
        dashboardId: dashboard.id,
        dashboardName: dashboard.label,
      });
    }
  };

  onOptionsChange = (options: ReportOptions) => {
    const { report, updateReportProp } = this.props;
    updateReportProp({
      ...report,
      options,
    });
  };

  submitForm = (reportData: ReportDTO) => {
    const { createReport, updateReport, report, reportId } = this.props;
    const { schedule, options, dashboardId } = report;
    const { name, replyTo, recipients, message } = reportData;

    const createOrUpdate = reportId ? updateReport : createReport;

    const reportDto: ReportDTO = {
      id: report ? report.id : undefined,
      name,
      recipients,
      dashboardId,
      replyTo,
      message,
      schedule,
      options,
    };

    createOrUpdate(reportDto);
  };

  getPreviewUrl() {
    const { report } = this.props;
    const { name, dashboardId, options } = report;

    if (!dashboardId) {
      return undefined;
    }

    const params: any = {
      title: name,
    };

    if (options.landscape) {
      // Use string param because macaron can't handle
      // bool param without value like http://grafana/api/render?landscape
      params.landscape = `${options.landscape}`;
    }

    return appendQueryToUrl(`api/reports/render/pdf/${dashboardId}`, toUrlParams(params));
  }

  render() {
    const { navModel, report, reportId, isLoading } = this.props;
    const { message, name, recipients, replyTo, schedule, dashboardId, dashboardName, options } = report;

    const heading = reportId ? `Edit ${name}` : 'New report';

    const dashboardSelected = dashboardId > 0;
    const currentDashboard = dashboardSelected ? { value: dashboardId, label: dashboardName } : undefined;
    const inputSize = 'lg';

    const previewUrl = this.getPreviewUrl();

    return (
      <Page navModel={navModel}>
        <Page.Contents isLoading={Boolean(isLoading && reportId)}>
          <h3 className="page-sub-heading">{heading}</h3>
          <Forms.Form onSubmit={this.submitForm} validateOn="onBlur">
            {({ register, errors, control }) => {
              return (
                <>
                  <Forms.Field label="Name" required invalid={!!errors.name} error="Name is required">
                    <Forms.Input
                      defaultValue={name}
                      name="name"
                      size={inputSize}
                      ref={register({ required: true })}
                      placeholder="System status report"
                    />
                  </Forms.Field>
                  <Forms.Field
                    label="Choose dashboard"
                    required
                    invalid={!!errors.dashboardId}
                    error="Dashboard is required"
                  >
                    <Forms.InputControl
                      name="dashboardId"
                      control={control}
                      as={DashboardPicker}
                      onSelected={(dashboard: SelectableValue) => {
                        this.onDashboardChange(dashboard);
                        // We need to manually set the form value for the form to trigger validation on change
                        control.setValue('dashboardId', dashboard?.id, true);
                      }}
                      size={inputSize}
                      defaultValue={currentDashboard?.value}
                      currentDashboard={currentDashboard}
                      rules={{ required: true }}
                    />
                  </Forms.Field>
                  <Forms.Field
                    label="Recipients"
                    required
                    invalid={!!errors.recipients}
                    error={errors.recipients?.message}
                  >
                    <Forms.TextArea
                      name="recipients"
                      size={inputSize}
                      ref={register({
                        required: 'Recipients are required',
                        validate: val => validateMultipleEmails(val) || 'Invalid email',
                      })}
                      placeholder="name@company.com;another.name@company.com;"
                      defaultValue={recipients}
                    />
                  </Forms.Field>
                  <Forms.Field label="Reply to">
                    <Forms.Input
                      name="replyTo"
                      size={inputSize}
                      ref={register}
                      placeholder="your.address@company.com - optional"
                      type="email"
                      defaultValue={replyTo}
                    />
                  </Forms.Field>
                  <Forms.Field label="Custom message">
                    <Forms.TextArea
                      name="message"
                      size={inputSize}
                      placeholder={message}
                      rows={10}
                      ref={register}
                      defaultValue={message}
                    />
                  </Forms.Field>

                  <h4>Options</h4>
                  <ReportOptionsPicker options={options} onChange={this.onOptionsChange} />

                  <h4>Scheduling</h4>
                  <ReportScheduling
                    schedulingOptions={schedule}
                    onModeChange={this.onModeChange}
                    onDayOfWeekChange={this.onDayOfWeekChange}
                    onTimeOfDayChange={this.onTimeOfDayChange}
                    onTimeZoneChange={this.onTimeZoneChange}
                  />

                  <div
                    className={css`
                      margin-top: 40px;
                    `}
                  >
                    <Forms.Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className={css`
                        margin-right: 15px;
                      `}
                    >
                      Save
                    </Forms.Button>

                    <Forms.LinkButton href={previewUrl} size="xs" target="_blank" variant="secondary">
                      Preview
                    </Forms.LinkButton>
                  </div>
                </>
              );
            }}
          </Forms.Form>
        </Page.Contents>
      </Page>
    );
  }
}

function mapStateToProps(state: EnterpriseStoreState) {
  const reportId = getRouteParamsId(state.location);
  return {
    navModel: getNavModel(state.navIndex, 'reports-list'),
    report: state.reports.report,
    isLoading: state.reports.isLoading,
    reportId,
  };
}

const mapActionsToProps = {
  updateReport,
  loadReport,
  createReport,
  clearReportState,
  updateReportProp,
};

export default connect(mapStateToProps, mapActionsToProps)(ReportPage);
