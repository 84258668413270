import React, { FC } from 'react';
import { TimeOfDayPicker, Forms } from '@grafana/ui';
import { dateTime, DateTime, SelectableValue } from '@grafana/data';
import { daysOfWeek, SchedulingFrequency, SchedulingOptions } from '../types';
import { TimeZonePicker } from '@grafana/ui/src/components/TimePicker/TimeZonePicker';

interface Props {
  schedulingOptions: SchedulingOptions;
  onModeChange: (mode: SchedulingFrequency) => void;
  onDayOfWeekChange: (day: SelectableValue<string>) => void;
  onTimeOfDayChange: (time: DateTime) => void;
  onTimeZoneChange: (timeZone: string) => void;
}

const frequencyOptions: SelectableValue[] = [
  { label: 'Weekly', value: SchedulingFrequency.Weekly },
  { label: 'Daily', value: SchedulingFrequency.Daily },
  { label: 'Hourly', value: SchedulingFrequency.Hourly },
];

export const ReportScheduling: FC<Props> = ({
  onModeChange,
  onDayOfWeekChange,
  onTimeOfDayChange,
  onTimeZoneChange,
  schedulingOptions,
}) => {
  const { frequency, hour, minute, timeZone } = schedulingOptions;

  const selectedDay = daysOfWeek.find(day => schedulingOptions.day === day.value);
  const inputSize = 'sm';

  return (
    <>
      <Forms.Field label="Frequency">
        <Forms.RadioButtonGroup options={frequencyOptions} onChange={onModeChange} value={frequency} />
      </Forms.Field>
      {frequency === SchedulingFrequency.Weekly && (
        <>
          <Forms.Field label="Day">
            <Forms.Select size={inputSize} onChange={onDayOfWeekChange} options={daysOfWeek} value={selectedDay} />
          </Forms.Field>
          <Forms.Field label="Time">
            <TimeOfDayPicker
              size={inputSize}
              onChange={onTimeOfDayChange}
              minuteStep={10}
              value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
            />
          </Forms.Field>
        </>
      )}
      {frequency === SchedulingFrequency.Daily && (
        <Forms.Field label="Time">
          <TimeOfDayPicker
            size={inputSize}
            minuteStep={10}
            onChange={onTimeOfDayChange}
            value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
          />
        </Forms.Field>
      )}
      {frequency === SchedulingFrequency.Hourly && (
        <Forms.Field label="At minute">
          <TimeOfDayPicker
            size={inputSize}
            showHour={false}
            minuteStep={10}
            onChange={onTimeOfDayChange}
            value={dateTime(new Date(`January 1, 1970 ${hour}:${minute}`))}
          />
        </Forms.Field>
      )}
      <Forms.Field label="Time zone">
        <TimeZonePicker value={timeZone} onChange={onTimeZoneChange} size={inputSize} />
      </Forms.Field>
    </>
  );
};
