import React, { PureComponent } from 'react';
import { Forms, ModalTabContent } from '@grafana/ui';
import { config } from 'app/core/config';
import { appendQueryToUrl, toUrlParams } from 'app/core/utils/url';
import { ShareModalTabProps } from 'app/features/dashboard/components/ShareModal';
import { reportLayouts } from '../types';

type Props = ShareModalTabProps;

interface State {
  pdfUrl: string;
  selectedLayout: string;
}

export class SharePDF extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pdfUrl: '',
      selectedLayout: reportLayouts[0].value,
    };
  }

  componentDidMount() {
    this.buildPdfLink();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { selectedLayout } = this.state;
    if (prevState.selectedLayout !== selectedLayout) {
      this.buildPdfLink();
    }
  }

  buildPdfLink() {
    const { dashboard } = this.props;
    const { selectedLayout } = this.state;

    let rootPath = window.location.origin + config.appSubUrl;
    if (rootPath.endsWith('/')) {
      rootPath.slice(0, -1);
    }
    let pdfUrl = `${rootPath}/api/reports/render/pdf/${dashboard.id}`;

    const params: any = {};
    if (selectedLayout === 'landscape') {
      params['landscape'] = 'true';
    }
    pdfUrl = appendQueryToUrl(pdfUrl, toUrlParams(params));

    this.setState({ pdfUrl });
  }

  onLayoutChange = (value: string) => {
    this.setState({ selectedLayout: value });
  };

  render() {
    const { onDismiss } = this.props;
    const { pdfUrl, selectedLayout } = this.state;

    return (
      <ModalTabContent iconClass="gicon gicon-reports">
        <p className="share-modal-info-text">Render dashboard as a PDF document.</p>
        <Forms.Field label="Layout">
          <Forms.RadioButtonGroup options={reportLayouts} value={selectedLayout} onChange={this.onLayoutChange} />
        </Forms.Field>
        <div className="gf-form-button-row">
          <Forms.LinkButton variant="primary" href={pdfUrl} target="blank">
            Save as PDF
          </Forms.LinkButton>
          <Forms.Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Forms.Button>
        </div>
      </ModalTabContent>
    );
  }
}
