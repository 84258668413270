import { getBackendSrv } from 'app/core/services/backend_srv';

export interface LicenseToken {
  jti: string;
  iss: string;
  sub: string;
  iat: number;
  exp: number;
  nbf: number;
  lexp: number;
  lid: number;
  max_users: number;
  included_admins: number;
  included_viewers: number;
  prod: string[];
  company: string;
}

export interface ActiveUserStats {
  active_admins_and_editors: number;
  active_viewers: number;
}

export const getLicenseToken = async (): Promise<LicenseToken> => {
  return await getBackendSrv().get('/api/licensing/token');
};

export const refreshLicenseStats = async (): Promise<ActiveUserStats> => {
  return await getBackendSrv().get('/api/licensing/refresh-stats');
};
