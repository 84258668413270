import React, { PureComponent } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { StoreState } from 'app/types';
import { NavModel } from '@grafana/data';
import { getNavModel } from 'app/core/selectors/navModel';
import { getLicenseToken, LicenseToken } from './state/api';
import Page from 'app/core/components/Page/Page';
import { UpgradeInfo } from 'app/features/admin/UpgradePage';
import { LicenseChrome } from 'app/features/admin/LicenseChrome';

interface Props {
  navModel: NavModel;
  getLicenseToken: typeof getLicenseToken;
}

interface State {
  token: LicenseToken;
  isLoading: boolean;
}

export class LicensePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      token: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const token = await this.props.getLicenseToken().catch(err => null);
    this.setState({ token, isLoading: false });
  }

  render() {
    const { navModel } = this.props;
    const { token, isLoading } = this.state;

    return (
      <Page navModel={navModel}>
        <Page.Contents isLoading={isLoading}>
          {token ? (
            <LicenceInfo token={token} />
          ) : (
            <UpgradeInfo editionNotice="You are running Grafana Enterprise without a license. The enterprise features are disabled." />
          )}
        </Page.Contents>
      </Page>
    );
  }
}

interface LicenseInfoProps {
  token: LicenseToken;
}

const LicenceInfo: React.FC<LicenseInfoProps> = ({ token }) => {
  return (
    <LicenseChrome header={'Enterprise license details'} subheader={token.company}>
      <table className="filter-table form-inline" style={{ marginBottom: '42px' }}>
        <tbody>
          <tr>
            <td style={{ width: '30%' }}>License ID</td>
            <td style={{ width: '70%' }}>
              {token.lid} (
              <a
                href={`${token.iss}/licenses/${token.lid}`}
                target="_blank"
                rel="noopener noreferer"
                aria-label="View details about your license in Grafana Cloud"
              >
                View Details
              </a>
              )
            </td>
          </tr>
          <tr>
            <td>Licensed URL</td>
            <td>
              <a href={token.sub} target="_blank" rel="noopener noreferer">
                {token.sub}
              </a>
            </td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{token.company}</td>
          </tr>
          <tr>
            <td>Products</td>
            <td>{token.prod}</td>
          </tr>
          <tr>
            <td>Max Users</td>
            <td>{token.max_users > 0 ? token.max_users : 'Unlimited'}</td>
          </tr>
          <tr>
            <td>Included Editors & Admins</td>
            <td>{token.included_admins > 0 ? token.included_admins : 'Unlimited'}</td>
          </tr>
          <tr>
            <td>Included Viewers</td>
            <td>{token.included_viewers > 0 ? token.included_viewers : 'Unlimited'}</td>
          </tr>
          <tr>
            <td>License Issued</td>
            <td>{new Date(token.nbf * 1000).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>License Expires</td>
            <td>{new Date(token.lexp * 1000).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td colSpan={2} className="admin-settings-section">
              Token Details
            </td>
          </tr>
          <tr>
            <td>Token ID</td>
            <td>{token.jti}</td>
          </tr>
          <tr>
            <td>Token Issued</td>
            <td>{new Date(token.iat * 1000).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Token Expires</td>
            <td>{new Date(token.exp * 1000).toLocaleDateString()}</td>
          </tr>
        </tbody>
      </table>
    </LicenseChrome>
  );
};

const mapStateToProps = (state: StoreState) => ({
  navModel: getNavModel(state.navIndex, 'licensing'),
  getLicenseToken: getLicenseToken,
});

export default hot(module)(connect(mapStateToProps)(LicensePage));
